<template>
    <div class="bookshelf-container" ref="bookshelf">
     <div class="shelftopthings">
        <div class="top-stack">
      <div
        class="stacked-book"
        v-for="(book, index) in topBooks"
        :key="index"
        :class="book.color"
        :style="{ transform: `rotate(${book.rotation}deg)` }"
      >
        <span class="book-title">{{ book.title }}</span>
      </div>
      
    </div>
   
    <img src="https://userimages-1255367272.cos.ap-shanghai.myqcloud.com/vase.png" alt="Vase" class="vase" />
    <clock />
   
    <img src=" https://userimages-1255367272.cos.ap-shanghai.myqcloud.com/basketball.png" alt="Vase" class="vase" />
     
    <img src=" https://userimages-1255367272.cos.ap-shanghai.myqcloud.com/basket.png" alt="Vase" class="vase" />
  
</div>
      <div class="bookshelf">
        <!-- 第一层：填满各种书籍 -->
        <div class="shelf-cell" v-for="(shelf, index) in shelves" :key="index">
          <div
            class="book"
            v-for="(book, idx) in shelf.books"
            :key="idx"
            :class="book.color"
          >
          <span class="book-title">{{ book.title }}</span>
          </div>
        </div>
      </div>
  
      <!-- 辐条轮子 -->
      <div class="wheel left">
        <div class="spokes">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="wheel right">
        <div class="spokes">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import clock from "./timer/clock.vue"
  const topBooks = ref([
  { title: "Advanced Mathematics", color: "blue" },
  { title: "Physics Essentials", color: "green" },
  { title: "Literature", color: "red" },
  { title: "Ancient History", color: "orange" },
  { title: "Psychology", color: "purple" }
]);
  const shelves = ref([
  { books: [
    { title: "Harry Potter", color: "green" },
    { title: "HTML & CSS", color: "blue" },
    { title: "JavaScript Guide", color: "red" },
    { title: "Data Science", color: "orange" },
    { title: "教 父", color: "orange" },
    { title: "Anatomy for Beginners", color: "purple" },
    { title: "Art of the Renaissance", color: "blue" }
  ]},
  { books: [
    { title: "Python 101", color: "purple" },
    { title: "Machine Learning", color: "green" },
    { title: "React & Vue", color: "blue" },
    { title: "Blockchain Basics", color: "red" },
    { title: "Medical Microbiology", color: "orange" },
    { title: "Digital Painting", color: "purple" },
    { title: "Histology Essentials", color: "red" }
  ]},
  { books: [
    { title: "Introduction to Anatomy", color: "blue" },
    { title: "Medical Ethics", color: "green" },
    { title: "The Art of Sculpture", color: "purple" },
    { title: "Color Theory", color: "orange" },
    { title: "Molecular Biology", color: "red" },
    { title: "Modern Architecture", color: "blue" }
  ]},
  { books: [
    { title: "Philosophy of Art", color: "purple" },
    { title: "Neuroscience Fundamentals", color: "red" },
    { title: "Painting Techniques", color: "green" },
    { title: "Biostatistics", color: "orange" },
    { title: "Surgery Handbook", color: "blue" },
    { title: "Aesthetic Principles", color: "purple" },
    { title: "Art and Perception", color: "green" }
  ]},
  { books: [
    { title: "Pharmacology", color: "orange" },
    { title: "HTML & CSS", color: "blue" },
    { title: "JavaScript Guide", color: "red" },
    { title: "Impressionism", color: "purple" },
    { title: "Cardiology Basics", color: "green" },
    { title: "Modern Art", color: "red" },
    { title: "Public Health", color: "blue" }
  ]},
  { books: [
    { title: "Harry Potter", color: "green" },
    { title: "HTML & CSS", color: "blue" },
    { title: "Biochemistry", color: "orange" },
    { title: "Anatomy Atlas", color: "purple" },
    { title: "Machine Learning", color: "red" },
    { title: "Blockchain Basics", color: "green" },
    { title: "Art Criticism", color: "purple" }
  ]},
  { books: [
    { title: "Neuroanatomy", color: "red" },
    { title: "Sculpture History", color: "blue" },
    { title: "Art Deco", color: "purple" },
    { title: "Introduction to Medicine", color: "orange" },
    { title: "AI and Art", color: "green" },
    { title: "Medical Genetics", color: "blue" }
  ]},
  { books: [
    { title: "Python for Data Science", color: "red" },
    { title: "Art and Emotion", color: "purple" },
    { title: "Clinical Psychology", color: "green" },
    { title: "Pathophysiology", color: "orange" },
    { title: "Web Design", color: "blue" },
    { title: "Aesthetics in Art", color: "green" },
    { title: "Art History", color: "purple" }
  ]},
  { books: [
    { title: "Digital Art", color: "orange" },
    { title: "Machine Learning", color: "green" },
    { title: "Neurobiology", color: "blue" },
    { title: "Visual Perception", color: "purple" },
    { title: "Blockchain in Medicine", color: "red" },
    { title: "Public Health Policy", color: "orange" },
    { title: "Fine Arts Fundamentals", color: "green" }
  ]},
  { books: [
    { title: "Color Psychology", color: "red" },
    { title: "Biology of Cells", color: "green" },
    { title: "Introduction to Painting", color: "purple" },
    { title: "HTML & CSS", color: "blue" },
    { title: "Contemporary Art", color: "purple" },
    { title: "JavaScript Guide", color: "red" },
    { title: "Advanced Anatomy", color: "orange" }
  ]},
  { books: [
    { title: "Medical Physiology", color: "purple" },
    { title: "JavaScript Essentials", color: "green" },
    { title: "Art of Impressionism", color: "blue" },
    { title: "Design Thinking", color: "orange" },
    { title: "Pathology", color: "red" },
    { title: "Visual Arts", color: "purple" },
    { title: "Introduction to Sculpture", color: "green" }
  ]},
  { books: [
    { title: "Creative Writing", color: "orange" },
    { title: "Blockchain Basics", color: "red" },
    { title: "Medical Imaging", color: "blue" },
    { title: "Introduction to Neuroscience", color: "green" },
    { title: "Painting Styles", color: "purple" },
    { title: "Public Health", color: "orange" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]},
  { books: [
    { title: "Medical Physiology", color: "purple" },
    { title: "JavaScript Essentials", color: "green" },
    { title: "Art of Impressionism", color: "blue" },
    { title: "Design Thinking", color: "orange" },
    { title: "Pathology", color: "red" },
    { title: "Visual Arts", color: "purple" },
    { title: "Introduction to Sculpture", color: "green" }
  ]},
  { books: [
    { title: "Creative Writing", color: "orange" },
    { title: "Blockchain Basics", color: "red" },
    { title: "Medical Imaging", color: "blue" },
    { title: "Introduction to Neuroscience", color: "green" },
    { title: "Painting Styles", color: "purple" },
    { title: "Public Health", color: "orange" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]},
  { books: [
    { title: "Medical Physiology", color: "purple" },
    { title: "JavaScript Essentials", color: "green" },
    { title: "Art of Impressionism", color: "blue" },
    { title: "Design Thinking", color: "orange" },
    { title: "Pathology", color: "red" },
    { title: "Visual Arts", color: "purple" },
    { title: "JavaScript Essentials", color: "green" },
    { title: "Art of Impressionism", color: "blue" },
    { title: "Design Thinking", color: "orange" },
    { title: "Pathology", color: "red" },
    { title: "Visual Arts", color: "purple" },
    { title: "Introduction to Sculpture", color: "green" }
  ]},
  { books: [
    { title: "Creative Writing", color: "orange" },
    { title: "Blockchain Basics", color: "red" },
    { title: "Medical Imaging", color: "blue" },
    { title: "Introduction to Neuroscience", color: "green" },
    { title: "Painting Styles", color: "purple" },
    { title: "Public Health", color: "orange" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]},
  { books: [
    { title: "Medical Physiology", color: "purple" },
    { title: "JavaScript Essentials", color: "green" },
    { title: "Art of Impressionism", color: "blue" },
    { title: "Design Thinking", color: "orange" },
    { title: "Pathology", color: "red" },
    { title: "Visual Arts", color: "purple" },
    { title: "Introduction to Sculpture", color: "green" }
  ]},
  { books: [
    { title: "Creative Writing", color: "orange" },
    { title: "Blockchain Basics", color: "red" },
    { title: "Medical Imaging", color: "blue" },
    { title: "Introduction to Neuroscience", color: "green" },
    { title: "Painting Styles", color: "purple" },
    { title: "Public Health", color: "orange" },
    { title: "Medical Imaging", color: "blue" },
    { title: "Introduction to Neuroscience", color: "green" },
    { title: "Painting Styles", color: "purple" },
    { title: "Public Health", color: "orange" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]},
  { books: [
    { title: "Medical Physiology", color: "purple" },
    { title: "JavaScript Essentials", color: "green" },
    { title: "Art of Impressionism", color: "blue" },
    { title: "Design Thinking", color: "orange" },
    { title: "Pathology", color: "red" },
    { title: "Visual Arts", color: "purple" },
    { title: "Art of Impressionism", color: "blue" },
    { title: "Design Thinking", color: "orange" },
    { title: "Pathology", color: "red" },
    { title: "Visual Arts", color: "purple" },
    { title: "Introduction to Sculpture", color: "green" }
  ]},
  { books: [
    { title: "Creative Writing", color: "orange" },
    { title: "Blockchain Basics", color: "red" },
    { title: "Medical Imaging", color: "blue" },
    { title: "Introduction to Neuroscience", color: "green" },
    { title: "Painting Styles", color: "purple" },
    { title: "Public Health", color: "orange" }
  ]},
  { books: [
    { title: "Basic Pharmacology", color: "purple" },
    { title: "AI in Art", color: "green" },
    { title: "Sculpture Techniques", color: "blue" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "Clinical Nutrition", color: "orange" },
    { title: "Art Critique", color: "red" },
    { title: "HTML & CSS", color: "blue" }
  ]}
]);

  
  const bookshelf = ref(null);
  let isDragging = false;
  let startX = 0;
  let initialX = 0;
  let wheelRotation = 0;
  
  const startDrag = (event) => {
    isDragging = true;
    startX = event.clientX;
    initialX = bookshelf.value.offsetLeft;
    bookshelf.value.style.cursor = "grabbing";
  };
  
  const drag = (event) => {
    if (isDragging) {
      const dx = event.clientX - startX;
      bookshelf.value.style.left = `${initialX + dx}px`;
  
      // 仅允许水平移动并旋转轮子
      wheelRotation += dx * 0.1; // 控制旋转速度
      bookshelf.value.querySelector(".left").style.transform = `rotate(${wheelRotation}deg)`;
      bookshelf.value.querySelector(".right").style.transform = `rotate(${wheelRotation}deg)`;
    }
  };
  
  const stopDrag = () => {
    isDragging = false;
    bookshelf.value.style.cursor = "grab";
  };
  
  onMounted(() => {
    bookshelf.value.addEventListener("mousedown", startDrag);
    document.addEventListener("mousemove", drag);
    document.addEventListener("mouseup", stopDrag);
  });
  
  onBeforeUnmount(() => {
    bookshelf.value.removeEventListener("mousedown", startDrag);
    document.removeEventListener("mousemove", drag);
    document.removeEventListener("mouseup", stopDrag);
  });
  </script>
  
  <style scoped>
  .bookshelf-container {
    position: absolute;
    width: 90%;
   
    
    margin: 0 auto;
    cursor: grab;
    transition: transform 0.3s ease;
    left: 10%;
  }
  
  .bookshelf-container:active {
    cursor: grabbing;
  }
  
  .bookshelf {
  display: grid;
  grid-template-rows: repeat(8, 1fr); /* 8行，每行代表一层 */
  grid-template-columns: repeat(4, 1fr); /* 4列，每列代表一个隔板 */
  gap: 4px; /* 隔板之间的间隙 */
  height: 800px; /* 总高度，可以调整 */
  padding: 10px;
  background-color: #3b2e2a; /* 深棕色 */
  border: 5px solid #5a4b42; /* 外边框颜色稍微加深 */
  border-radius: 10px;
  
}

.shelf-cell {
  background-color: #3b2e2a; /* 每个格子的背景颜色 */
  border: 2px solid #2a1e19; /* 更深的棕色边框，用于模拟缝隙 */
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); /* 内部阴影增强立体效果 */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
}
  
  .book {
    max-width: 30px;
    width: 2vw;
    height: 100px;
    background-color: #d2691e;
    border: 2px solid #8b5e3c;
    border-radius: 2px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    position: relative;
    transform: rotate(-2deg);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.7em;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 2px;
  }
  
  .book.green {
    background-color: darkgreen;
  }
  .book.blue {
    background-color: darkblue;
  }
  .book.red {
    background-color: darkred;
  }
  .book.orange {
    background-color: orange;
    color: black;
  }
  .book.purple {
    background-color: purple;
  }
  
  .book span {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
  }
  
  .wheel {
    position: absolute;
    bottom: -20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #333;
    background: radial-gradient(circle at center, #555 30%, transparent 31%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .wheel.left {
    left: 15%;
  }
  
  .wheel.right {
    right: 15%;
  }
  
  .wheel .spokes {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .wheel .spokes div {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #333;
    transform-origin: center;
  }
  
  .wheel .spokes div:nth-child(1) {
    transform: rotate(0deg);
  }
  .wheel .spokes div:nth-child(2) {
    transform: rotate(30deg);
  }
  .wheel .spokes div:nth-child(3) {
    transform: rotate(60deg);
  }
  .wheel .spokes div:nth-child(4) {
    transform: rotate(90deg);
  }
  .wheel .spokes div:nth-child(5) {
    transform: rotate(120deg);
  }
  .wheel .spokes div:nth-child(6) {
    transform: rotate(150deg);
  }

/* 顶部横向书本的样式 */
.top-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.stacked-book {
  width: 100px; /* 统一书本长度 */
  height: 20px;
  margin-bottom: -5px; /* 让书本上下堆叠 */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2691e;
  border: 2px solid #8B5E3C;
  color: white;
  font-size: 0.6em;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 2px;
  transform-origin: center;
}
/* 使书名内容宽度固定并居中 */
.book-title {
  display: inline-block; /* 使用 inline-block 而不是 inline */
  width: 100%; /* 固定宽度与书本一致 */
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 每种颜色的书 */
.horizontal-book.blue { background-color: darkblue; }
.horizontal-book.green { background-color: darkgreen; }
.horizontal-book.red { background-color: darkred; }
.horizontal-book.orange { background-color: orange; color: black; }
.horizontal-book.purple { background-color: purple; }
.stacked-book.blue { background-color: darkblue; }
.stacked-book.green { background-color: darkgreen; }
.stacked-book.red { background-color: darkred; }
.stacked-book.orange { background-color: orange; color: black; }
.stacked-book.purple { background-color: purple; }


.shelftopthings{
    display: flex;
    flex-direction: row;
}
.vase {
  width: 80px;  /* 设置花瓶的宽度，可以根据需要调整 */
  height: auto; /* 保持比例自动调整高度 */
}



  </style>
  